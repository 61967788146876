/* =============================================================
 * import
 * ========================================================== */
import {gsap} from 'gsap';
import {CustomEase} from "gsap/CustomEase";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollSmoother} from "gsap/ScrollSmoother";
// import {intervalObserver} from "./intervalObserver";
import {isMobile} from "./common";
// import {mediaQuery} from "./common";

gsap.registerPlugin(CustomEase, ScrollTrigger, ScrollSmoother);
CustomEase.create("fadeCurtain", ".25, .46, .45, .94");


let smoothScrub;
let normalizeScrollFlg;
let effectsFlg;

if (isMobile()) {
  smoothScrub = 0;
  normalizeScrollFlg = false;
  effectsFlg = false;
  ScrollTrigger.normalizeScroll(false);
  ScrollTrigger.config({
    limitCallbacks: true,
    ignoreMobileResize: true,
  });
} else {
  smoothScrub = 1.5;
  normalizeScrollFlg = true;
  effectsFlg = true;
}


// ScrollSmootherのオプション
export const ScrollSmootherOpt = { // eslint-disable-line @typescript-eslint/no-unused-vars
  wrapper: '.main',
  content: '.main_container',
  effects: effectsFlg,
  normalizeScroll: normalizeScrollFlg,
  ignoreMobileResize: true,
  smooth: smoothScrub,
  // smoothTouch: 0,
};


export let smoother;


// ScrollSmootherを初期化
if (isMobile()) {
  smoother = null;
  const main = document.querySelector('.main');
  main.classList.add('touch_device'); // touch device時に各種アニメーション等々を消すためのクラス（landing時）
} else {
  smoother = ScrollSmoother.create(ScrollSmootherOpt); // eslint-disable-line @typescript-eslint/no-unused-vars
}


/* =============================================================
 * function
 * ========================================================== */

export function initParallax() {
  if (!isMobile()) {
    ScrollSmoother.create(ScrollSmootherOpt);
  }

  floatingHeader();
  mvPinScroll();
  imageScrollEffect();

  /*
  window.addEventListener('resize', () => {

    if (!isMobile()) {
      // PCリサイズ時
      scrollScaleImage();
      scrollScaleImageBusiness();
      floatingHeader();
      scrollTxtSwiper();
      scrollTxtClipper();
      loopSvgAnimation();
      imageScrollEffect();
    } else {

    }

    const newIsPortrait = window.innerHeight > window.innerWidth;
    if (newIsPortrait !== isPortrait) {
      isPortrait = newIsPortrait;
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      setupAnimation();
    }
  });
  */

  ScrollTrigger.refresh();
}


function floatingHeader() {

  const targets = gsap.utils.toArray(".dark");
  const header = document.querySelector('#header');
  const hum = document.querySelector('#hum');

  if (targets.length > 0) {
    targets.forEach((target) => {
      ScrollTrigger.create({
        trigger: target,
        start: 'top top+=100',
        end: 'bottom top',
        scrub: true,
        onToggle: self => {
          if (self.isActive) {
            header.classList.add('negative');
            hum.classList.add('negative');
          } else {
            header.classList.remove('negative');
            hum.classList.remove('negative');
          }
        },
      });
    });
  }

  const heads = gsap.utils.toArray(".mv, .kv, .contents_head");
  if (heads.length > 0) {
    heads.forEach((target) => {
      ScrollTrigger.create({
        trigger: target,
        start: 'top top+=100',
        end: 'bottom top',
        scrub: true,
        onToggle: self => {
          if (self.isActive) {
            header.classList.remove('scrolled');
            hum.classList.remove('scrolled');
          } else {
            header.classList.add('scrolled');
            hum.classList.add('scrolled');
          }
        },
      });
    });
  }

}


function mvPinScroll() {

  const target = gsap.utils.toArray([".main_figure", ".kv_slider"]);

  if (target !== '') {

    gsap.to(target, {
      scale: 1.1,
      scrollTrigger: {
        trigger: target,
        pin: true,
        start: "top top",
        end: 'bottom top',
        pinSpacing: false,
        scrub: 1,
        invalidateOnRefresh: true, // update on resize
      },
    });

  }

}


function imageScrollEffect() {
  // Parallax effect 1
  const pax1 = gsap.utils.toArray(".js-pax-outer");
  if (pax1.length > 0) {
    pax1.forEach(elm => {
      if (window.getComputedStyle(elm).getPropertyValue('display') === 'inline') {
        elm.style.display = 'inline-block';
      }
      const elmOuterHeight = elm.clientHeight;
      const elmInner = elm.querySelector(".js-pax-inner");
      const elmInnerHeight = elmInner.clientHeight;
      const elmDiffHeight = elmInnerHeight - elmOuterHeight;

      gsap.from(elmInner, {
        y: -elmDiffHeight,
        scrollTrigger: {
          trigger: elm,
          start: 'top bottom', // top-=100 bottom
          end: 'bottom top',
          scrub: 1,
          invalidateOnRefresh: true, // update on resize
        },
      });
    });
  }
}
