/* =============================================================
 * import
 * ========================================================== */
import {eventObserver} from "../modules/eventObserver";
// import {isMobile} from "../modules/common";
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
// import {mediaQuery, isMobile} from "../modules/common";

gsap.registerPlugin(CustomEase);
CustomEase.create("syu", ".645, .045, .355, 1");


/* =============================================================
 * export function
 * ========================================================== */
export function before() {
  const $body = document.body;
  $body.classList.remove('top_page');
  $body.classList.add('sub_page');
}


export function after(data) {
  const listeners = [];
  let mv;
  let kv;
  // mouseStoker();
  const header = document.querySelector('#header');

  if (data.container) {
    mv = data.container.querySelector('.mv');
    kv = data.container.querySelector('.kv');
  } else {
    mv = document.querySelector('.mv');
    kv = document.querySelector('.kv');
  }

  if (mv) {
    if (mv.classList.contains('dark')) {
      header.classList.add('negative');
    } else {
      header.classList.remove('negative');
    }
  } else if (kv) {
    if (kv.classList.contains('dark')) {
      header.classList.add('negative');
    } else {
      header.classList.remove('negative');
    }
  } else {
    header.classList.remove('negative');
  }

  const btn = document.querySelectorAll('.totop');
  if (btn.length > 0) {
    btn.forEach(elem => {
      elem.addEventListener('click', toTop, false);
      listeners.push([elem, 'click', toTop, false]);
    });
  }

/*
  if (mediaQuery() !== 'sp') {
    const footerOffer = data.container.querySelector('.footer_offer');
    if (footerOffer != null) {
      const footerOfferLink = footerOffer.querySelectorAll('.footer_offer_link');

      let obj;
      footerOfferLink.forEach((elem) => {
        obj = {
          elem: elem,
          handleEvent: footerOfferLinkEnter,
        };
        elem.addEventListener('mouseenter', obj, false);
        listeners.push([elem, 'mouseenter', obj, false]);

        obj = {
          elem: elem,
          handleEvent: footerOfferLinkLeave,
        };
        elem.addEventListener('mouseleave', obj, false);
        listeners.push([elem, 'mouseleave', obj, false]);
        elem.addEventListener('click', obj, false);
        listeners.push([elem, 'click', obj, false]);
      });
    }
  }
*/


  eventObserver.observeEvents(listeners);
}


/* =============================================================
 * function
 * ========================================================== */

export function toTop() {
  gsap.to(window, {duration: 1, scrollTo: 0});
}


/* =============================================================
 * footerOfferLinkEnter
 * ========================================================== */
/*
export function footerOfferLinkEnter(e) {

  const elm = e.target;
  const elmParent = elm.parentNode;
  const elmNext = elm.nextElementSibling;

  let insetVal;
  if (elmParent.classList.contains('footer_offer_catalog')) {
    insetVal = 'inset(0 50% 0 0)';
  } else {
    insetVal = 'inset(0 0 0 50%)';
  }

  if (elmNext.classList.contains('footer_offer_figure')) {
    elmParent.style.zIndex = "2"; // 横に伸ばす際、下に隠さない
    gsap.fromTo(elmNext, {
      webkitClipPath: insetVal,
      clipPath: insetVal,
    }, {
      webkitClipPath: "inset(0% 0% 0% 0%)",
      clipPath: "inset(0% 0% 0% 0%)",
      ease: 'syu',
      duration: .4,
    });
  }
}
*/


/* =============================================================
 * footerOfferLinkLeave
 * ========================================================== */
/*
export function footerOfferLinkLeave(e) {
  const elm = e.target;
  const elmParent = elm.parentNode;
  const elmNext = elm.nextElementSibling;

  let insetVal;
  if (elmParent.classList.contains('footer_offer_catalog')) {
    insetVal = 'inset(0 50% 0 0)';
  } else {
    insetVal = 'inset(0 0 0 50%)';
  }


  if (elmNext.classList.contains('footer_offer_figure')) {
    elmParent.style.zIndex = "1"; // 元に戻す際、下に隠さない
    gsap.fromTo(elmNext, {
      webkitClipPath: "inset(0% 0% 0% 0%)",
      clipPath: "inset(0% 0% 0% 0%)",
    }, {
      webkitClipPath: insetVal,
      clipPath: insetVal,
      ease: 'syu',
      duration: .4,
      onComplete: () => {
        elmParent.style.zIndex = "0"; // マウスを外した動きが完了した際、初期化
        elmNext.removeAttribute('style');
      },
    });
  }
}
*/


/*
function mouseStoker() {

  if (!isMobile()) {
    const cursor = document.getElementById("cursor");
    const pos = {x: window.innerWidth / 2, y: window.innerHeight / 2}; // カーソル要素の座標（リサイズを考慮し初期位置を画面中央に設定）
    const mouse = {x: pos.x, y: pos.y}; // マウスカーソルの座標
    const speed = 0.5; // 0.01〜1 数値が大きほど早い

    // カーソルの座標を設定する
    const cursorSetX = gsap.quickSetter(cursor, "x", "px");
    const cursorSetY = gsap.quickSetter(cursor, "y", "px");

    const mouseStokerCallback = (pos) => {

      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      cursorSetX(pos.x);
      cursorSetY(pos.y);

    };

    // マウスカーソル座標を取得する
    document.addEventListener("mousemove", function(event) {
      mouse.x = event.pageX;
      mouse.y = event.pageY;
    });

    // マウスカーソル座標を更新する
    window.addEventListener("resize", function() {
      pos.x = window.innerWidth / 2;
      pos.y = window.innerHeight / 2;
      gsap.ticker.remove(mouseStokerCallback);
      gsap.ticker.add(function() {
        mouseStokerCallback(pos);
      });
    });

    // レンダリングする
    gsap.ticker.add(function() {
      mouseStokerCallback(pos);
    });
  }
}
*/
