/* =============================================================
 * import
 * ========================================================== */
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(CustomEase, ScrollTrigger);
CustomEase.create("slide", ".32, .95, .6, 1");

/* =============================================================
 * function
 * ========================================================== */
// let swiper;

export default function exe() {

  // 遷移元ページが存在しない場合（TOPページにランディングした場合）のみ実行
  /*
  const namespace = data.current.namespace; // barbajsからdataを取得
  if (namespace === '') {
    console.log('landing');
  }
  */

  kvSlider();
}


function kvSlider() {
  const item = document.querySelectorAll('.kv_slider_item');
  const delay = 0;
  let index = 0;
  const max = item.length;  // スライダー要素の数

  // data-index属性の初期値設定
  if (max > 0) {
    item.forEach((item, i) => {
      item.dataset.index = max - i;
    });
  }

  // sliderOption
  const opt = {
    classNameShow: 'active',  // 付与するクラス名
    classNameHide: 'hidden',  // 付与するクラス名
    firstDelay: delay,     // 初めのクラス付与までの遅延時間
    duration: 6000,       // 間隔
    startImmediate: true, // 初期状態から1つ目に付与するのか
  };

  if (opt.startImmediate) {
    item[index].classList.add(opt.classNameShow);
  }

  const execCycle = () => {

    // item[index].classList.remove(opt.className);
    index = (++index === max) ? 0 : index;
    // item[index].classList.add(opt.className);

    item.forEach(e => {
      // 各アイテムのZindexをdataset.indexを元に再計算
      let n = e.dataset.index;
      n = (++n === max + 1) ? 1 : n;
      e.style.zIndex = n; // z-indexを設定
      e.dataset.index = n; // data-indexを上書き
      e.classList.remove(opt.classNameShow);
      e.classList.remove(opt.classNameHide);

      // active, hiddenクラスの付け替え
      let indexBefore = index + 1;
      indexBefore = (index + 1 === max) ? 0 : indexBefore;
      item[index].classList.add(opt.classNameShow);
      item[indexBefore].classList.add(opt.classNameHide);
    });
  };

  setTimeout(function(){
    setInterval(execCycle, opt.duration); // eslint-disable-line @typescript-eslint/no-unused-vars
  }, opt.firstDelay);
}


