/* =============================================================
 * import
 * ========================================================== */
import ScrollHint from 'scroll-hint';
import $ from "jquery";
import {mediaQuery} from '../modules/common';
import {eventObserver} from "../modules/eventObserver";

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  slideDownBtn();
  tableScrollHint();

  window.addEventListener('resize', function() {
    if (mediaQuery() !== 'sp') {
      slideDownBtn();
    }
  });

  window.addEventListener("orientationchange", function() {
    if (mediaQuery() !== 'sp') {
      tableScrollHint();
      slideDownBtn();
    }
  });
}

function tableScrollHint() {

  // wrap table settings
  const contentsArea = document.querySelectorAll(".contents_post table");
  if (contentsArea !== null) {
    const tableAry = Array.prototype.slice.call(contentsArea);
    tableAry.forEach(function(e) {
      if (!e.closest(".table_wrap")) {
        e.outerHTML = '<div class="table_wrap">' + e.outerHTML + '</div>';
      }
    });
  }

  // scrollableJS settings
  const scrollableTrigger = document.querySelectorAll(".table_wrap");
  if (scrollableTrigger !== null) {
    const scrollableAry = Array.prototype.slice.call(scrollableTrigger);
    scrollableAry.forEach(function(scrollable) {
      const scrollHintIcon = scrollable.querySelector('.scroll-hint-icon-wrap');
      if (scrollHintIcon) {
        scrollHintIcon.remove();
      }
    });
    new ScrollHint(scrollableAry, {
      scrollHintIconAppendClass: 'scroll-hint-icon-white',
      // applyToParents: true,
      i18n: {
        scrollable: 'スクロールできます',
      },
    });
  }
}

function slideDownBtn() {
  const listeners = [];
  const btn = $(".category_selected");
  const area = $(".category_box");
  const obj = {
    btn: btn,
    area: area,
  };
  const slide = (e) => {
    e.data.btn.toggleClass('open');
    e.data.area.toggleClass('open').stop().slideToggle();
  };

  if (btn.length) {
    if (mediaQuery() === 'sp') {
      btn.on('click', obj, slide);
      listeners.push([btn, 'click', slide, '$']);
    } else {
      btn.off('click', slide); // リサイズイベントで再実行される可能性があるため、PCサイズでは明示的に解除
    }
  }

  eventObserver.observeEvents(listeners);
}
