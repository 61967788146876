/* =============================================================
 * import
 * ========================================================== */
import {eventObserver} from "./eventObserver";
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";
import {mediaQuery} from "./common";

gsap.registerPlugin(CustomEase);
CustomEase.create("slide", ".32, .95, .6, 1");
CustomEase.create("curtain", ".25, .43, .78, .71");


/* =============================================================
 * globalNavi
 * グローバルナビに関する実行関数
 * ========================================================== */

export function globalNavi() {
  const listElements = document.querySelectorAll('.header_gnav_list_item_link');
  const childLink = document.querySelectorAll('.header_gnav_list_child_list_item_link');
  const parentElements = document.querySelectorAll('.header_gnav_list_item.parent');
  const childElements = document.querySelectorAll('.header_gnav_list_child');
  const filterElement = document.querySelector('.header_gnav_filter');
  const touchPoint = document.querySelectorAll('.header_touchpoint_cta_link');
  const hum = document.querySelector('.hum');
  const logo = document.querySelector('.header_logo_link');
  const listeners = [];
  let child;
  let obj;

  const addEvent = () => {

    if (mediaQuery(window.innerWidth) !== 'sp') {

      // PC

      // MouseEnter/Leave
      if (parentElements) {
        parentElements.forEach((elem) => {
          obj = {
            elem: elem,
            handleEvent: globalNaviLinkEnter,
          };
          elem.addEventListener('mouseenter', obj, false);
          listeners.push([elem, 'mouseenter', obj, false]);

          obj = {
            elem: elem,
            handleEvent: globalNaviLinkLeave,
          };
          elem.addEventListener('mouseleave', obj, false);
          listeners.push([elem, 'mouseleave', obj, false]);
          elem.addEventListener('click', obj, false);
          listeners.push([elem, 'click', obj, false]);
        });
      }

      if (childElements) {
        childElements.forEach((elem) => {
          gsap.set(elem, {
            autoAlpha: 0,
          });
        });
      }

      if (filterElement) {
        filterElement.classList.remove('open');
        gsap.set(filterElement, {
          autoAlpha: 0,
        });
      }

    } else {

      // SP
      if (hum) {
        hum.addEventListener('click', humberger);
        logo.addEventListener('click', humberger);
        listeners.push([hum, 'click', humberger]);
        listeners.push([logo, 'click', humberger]);

        listElements.forEach((elem) => {
          if (elem.parentNode.classList.contains('parent') === false) {
            elem.addEventListener('click', humberger, false);
            listeners.push([elem, 'click', humberger]);
          }
        });
        touchPoint.forEach((elem) => {
          elem.addEventListener('click', humberger, false);
          listeners.push([elem, 'click', humberger]);
        });
        childLink.forEach((elem) => {
          elem.addEventListener('click', humberger, false);
          listeners.push([elem, 'click', humberger]);
        });
        parentElements.forEach((elem) => {
          child = elem.querySelector('.header_gnav_list_item_link');
          child.addEventListener('click', subMenu, false);
          listeners.push([child, 'click', subMenu]);
        });
      }

    }
    eventObserver.observeEvents(listeners);
  };

  const removeEvent = () => {
    if (parentElements) {
      parentElements.forEach((elem) => {
        obj = {
          elem: elem,
          handleEvent: globalNaviLinkEnter,
        };
        elem.removeEventListener('mouseenter', obj, false);
        obj = {
          elem: elem,
          handleEvent: globalNaviLinkLeave,
        };
        elem.removeEventListener('mouseleave', obj, false);
        elem.removeEventListener('click', obj, false);
      });
    }

    if (hum) {
      hum.removeEventListener('click', humberger);
      logo.removeEventListener('click', humberger);
      listElements.forEach((elem) => {
        elem.removeEventListener('click', humberger, false);
      });
      touchPoint.forEach((elem) => {
        elem.removeEventListener('click', humberger, false);
      });
      childLink.forEach((elem) => {
        elem.removeEventListener('click', humberger, false);
      });
      parentElements.forEach((elem) => {
        child = elem.querySelector('.header_gnav_list_item_link');
        child.removeEventListener('click', subMenu, false);
      });
    }

    const headerContent = document.querySelector('.header_content');
    const headerGnavList = document.querySelectorAll('.header_gnav_list_item');
    const headerTouchpoint = document.querySelectorAll('.header_touchpoint_cta_link');
    headerContent.removeAttribute('style');
    headerGnavList.forEach((elem) => {
      elem.removeAttribute('style');
    });
    headerTouchpoint.forEach((elem) => {
      elem.removeAttribute('style');
    });
  };

  addEvent();

  window.addEventListener('resize', function() {
    removeEvent();
    addEvent();
  });

  window.addEventListener('orientationchange', function() {
    removeEvent();
    addEvent();
  });

}


/* =============================================================
 * globalNaviLinkEnter
 * グローバルナビへmouseenterした際の挙動
 * ========================================================== */
export function globalNaviLinkEnter(e) {

  const parentElm = e.target;
  const childElm = e.target.querySelector('.header_gnav_list_child');
  const filterElm = document.querySelector('.header_gnav_filter');

  parentElm.classList.add('open');
  childElm.classList.add('open');
  filterElm.classList.add('open');

  gsap.to(childElm, {
    autoAlpha: 1,
    ease: 'slide',
    duration: .6,
  });

  gsap.to(filterElm, {
    autoAlpha: .3,
    ease: 'slide',
    duration: .6,
  });
}


/* =============================================================
 * globalNaviLinkLeave
 * グローバルナビからmouseleaveした際の挙動
 * ========================================================== */
export function globalNaviLinkLeave(e) {

  const parentElm = e.target;
  const childElm = e.target.querySelector('.header_gnav_list_child');
  const filterElm = document.querySelector('.header_gnav_filter');

  parentElm.classList.remove('open');
  childElm.classList.remove('open');
  filterElm.classList.remove('open');

  gsap.to(childElm, {
    autoAlpha: 0,
    ease: 'slide',
    duration: .6,
  });

  gsap.to(filterElm, {
    autoAlpha: 0,
    ease: 'slide',
    duration: .6,
  });
}


/* =============================================================
 * humberger
 * ハンバーガーメニューの制御
 * ========================================================== */

export function humberger() {

  const html = document.documentElement;
  const humBg = document.querySelector('.hum_bg');
  const hum = document.querySelector('.hum');
  const header = document.getElementById('header');
  const filter = document.getElementById('filter');

  if (hum) {

    /*
    if (hum.classList.contains('ing')) {
      return;
    }
    */

    if (hum.classList.contains('close')) {

      // 閉じている時
      if (!this.classList.contains('header_logo_link')) {

        humBg.classList.remove('close');
        hum.classList.remove('close');
        header.classList.remove('close');
        filter.classList.remove('close');

        humBg.classList.toggle('open');
        hum.classList.toggle('open');
        header.classList.toggle('open');
        filter.classList.toggle('open');
        html.classList.toggle('is-fixed');

        // hum.classList.add('ing');
        // humbergerAnim('open');
      }

    } else if (humBg.classList.contains('open')) {

      // 開いている時
      humBg.classList.add('close');
      hum.classList.add('close');
      header.classList.add('close');
      filter.classList.add('close');

      humBg.classList.toggle('open');
      hum.classList.toggle('open');
      header.classList.toggle('open');
      filter.classList.toggle('open');
      html.classList.toggle('is-fixed');

      // hum.classList.add('ing');
      // humbergerAnim('close');

    } else {

      // 初動
      if (!this.classList.contains('header_logo_link')) {
        humBg.classList.toggle('open');
        hum.classList.toggle('open');
        header.classList.toggle('open');
        filter.classList.toggle('open');
        html.classList.toggle('is-fixed');

        // hum.classList.add('ing');
        // humbergerAnim('open');
      }
    }

  }

}

/*
function humbergerAnim(which) {

  const tl = gsap.timeline({
    repeat: 0,
    ease: "curtain",
  });

  const hum = document.querySelector('#hum');
  const humBtn = document.querySelector('.hum');
  const nav = document.querySelector('.header_content');
  const bg = document.querySelector('.hum_bg');
  const list = document.querySelectorAll('.header_gnav_list_item');
  const listBottom = document.querySelectorAll('.header_touchpoint_cta_link');
  const copyright = document.querySelector('.header_copyright_txt');

  if (which === 'open') {

    // open animation
    gsap.set(bg, {
      autoAlpha: 1,
    });
    gsap.set(nav, {
      autoAlpha: 1,
    });

    tl
      .fromTo(humBtn, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        webkitClipPath: 'inset(0% 50% 0% 50%)',
        clipPath: 'inset(0% 50% 0% 50%)',
        duration: .6,
      }, "<")

      .fromTo(bg, {
        webkitClipPath: 'inset(0% 0% 0% 100%)',
        clipPath: 'inset(0% 0% 0% 100%)',
      }, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .8,
      }, "<")

      .call(toggleClass, [hum, 'ing'], ">")

      .fromTo(list, {
        x: '30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 0% 0% 100%)',
        clipPath: 'inset(0% 0% 0% 100%)',
      }, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .5,
        stagger: {
          each: .05,
        },
      }, ">-=.6")

      .fromTo(listBottom, {
        x: '30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 0% 0% 100%)',
        clipPath: 'inset(0% 0% 0% 100%)',
      }, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .5,
        stagger: {
          each: .05,
        },
      }, ">-=.5")

      .fromTo(copyright, {
        x: '30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 0% 0% 100%)',
        clipPath: 'inset(0% 0% 0% 100%)',
      }, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .5,
      }, "-=.6")

      .fromTo(humBtn, {
        webkitClipPath: 'inset(0% 50% 0% 50%)',
        clipPath: 'inset(0% 50% 0% 50%)',
      }, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .8,
      }, "<")
    ;

  } else {

    // close animation

    tl
      .fromTo(humBtn, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        webkitClipPath: 'inset(0% 50% 0% 50%)',
        clipPath: 'inset(0% 50% 0% 50%)',
        duration: .8,
      }, "<")

      .fromTo(list, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        // x: '-30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        duration: .5,
        stagger: {
          each: .05,
        },
      }, "<+=0")

      .fromTo(listBottom, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .5,
      }, {
        // x: '-30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        duration: .5,
      }, ">-=.5")

      .fromTo(copyright, {
        x: '0%',
        autoAlpha: 1,
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        // x: '-30%',
        autoAlpha: 0.5,
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        duration: .5,
      }, ">-=.5")

      .fromTo(bg, {
        // webkitClipPath: 'polygon(0% 0%, 0% 100%, 50% 100%, 50% 0%, 50% 0%, 50% 100%, 100% 100%, 100% 0%)',
        // clipPath: 'polygon(0% 0%, 0% 100%, 50% 100%, 50% 0%, 50% 0%, 50% 100%, 100% 100%, 100% 0%)',
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
      }, {
        // webkitClipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 0%)',
        // clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 0%)',
        webkitClipPath: 'inset(0% 100% 0% 0%)',
        clipPath: 'inset(0% 100% 0% 0%)',
        duration: .8,
      }, ">-=.2")

      .fromTo(humBtn, {
        webkitClipPath: 'inset(0% 50% 0% 50%)',
        clipPath: 'inset(0% 50% 0% 50%)',
      }, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .6,
      }, ">")

      .set(nav, {
        autoAlpha: 0,
        zIndex: 0,
      }, ">")

      .set(bg, {
        autoAlpha: 0,
      }, ">")

      .call(toggleClass, [hum, 'ing'], ">")
    ;

  }
}
*/


function subMenu(e) {
  const parent = e.target;
  const target = e.target.nextElementSibling;
  if (target.classList.contains('open')) {
    gsap.to(target, {
      autoAlpha: 0,
      height: 0,
    });
    parent.classList.remove('open');
    target.classList.remove('open');
  } else {
    gsap.set(target, {
      height: 0,
    });
    gsap.to(target, {
      autoAlpha: 1,
      height: 'auto',
    });
    parent.classList.add('open');
    target.classList.add('open');
  }
}


/* =============================================================
 * toggleClass
 * ========================================================== */

/*
function toggleClass(e, n) {
  e.classList.toggle(n);
}
*/
