/* =============================================================
 * import
 * ========================================================== */
import ScrollHint from 'scroll-hint';
import {isMobile} from "../modules/common";

/* =============================================================
 * function
 * ========================================================== */


export default function exe() {
  tableScrollHint();

  window.addEventListener('resize', function() {
    if (!isMobile()) {
      tableScrollHint();
    }
  });

  window.addEventListener("orientationchange", function() {
    tableScrollHint();
  });
}

function tableScrollHint() {

  const convertRowToThead = (row) => {
    row.querySelectorAll('td').forEach(td => {
      const th = document.createElement('th');
      th.innerHTML = td.innerHTML;

      // Copy all attributes from td to th
      for (const attr of td.attributes) {
        th.setAttribute(attr.name, attr.value);
      }

      td.parentNode.replaceChild(th, td);
    });
  };

  const tables = document.querySelectorAll(".contents_post table");
  if (tables !== null) {

    // Move the second row to thead
    tables.forEach(function(table) {
      let thead = table.querySelector('thead');
      const tbody = table.querySelector('tbody');
      if (tbody) {
        if (!thead) {
          thead = document.createElement('thead');
          table.insertBefore(thead, tbody);
        }
        const rows = tbody.querySelectorAll('.row-1, .row-2');
        if (rows.length !== 0) {
          rows.forEach(row => {
            thead.appendChild(row);
            convertRowToThead(row);
          });
        }
      }
    });

    // wrap table settings
    const tableAry = Array.prototype.slice.call(tables);
    tableAry.forEach(function(e) {
      if (!e.closest(".table_wrap")) {
        e.outerHTML = '<div class="table_wrap">' + e.outerHTML + '</div>';
      }
    });
  }


  // scrollableJS settings
  const scrollableTrigger = document.querySelectorAll(".table_wrap");
  if (scrollableTrigger !== null) {
    const scrollableAry = Array.prototype.slice.call(scrollableTrigger);
    scrollableAry.forEach(function(scrollable) {
      const scrollHintIcon = scrollable.querySelector('.scroll-hint-icon-wrap');
      if (scrollHintIcon) {
        scrollHintIcon.remove();
      }
    });
    new ScrollHint(scrollableAry, {
      scrollHintIconAppendClass: 'scroll-hint-icon-white',
      // applyToParents: true,
      i18n: {
        scrollable: 'スクロールできます',
      },
    });
  }
}
